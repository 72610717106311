<template>
  <div class="goodsGetails">
    <!-- 版权实务的 -->
    <!-- 顶部nav -->
    <knowledgeServiceNav :index="2"></knowledgeServiceNav>

    <!-- 商品详情 -->
    <PracticalInfo v-if="goodsData" :goodsData="goodsData" />

    <!-- 为你推荐 -->
    <!-- <Recommended v-if="goodsData" :fuID="goodsData.parentId" /> -->

    <!-- 底部tabs  -->
    <GoodsBottomTab v-if="goodsData" :goodsData="goodsData" />
  </div>
</template>

<script>
import { searchID } from "api/goodsGetails";
import knowledgeServiceNav from "components/KnowledgeServiceComponent/knowledgeServiceNav";
import PracticalInfo from "components/copyrightDetails/PracticalInfo";
// import Recommended from "components/GoodsGetailsComponent/Recommended";
import GoodsBottomTab from "components/GoodsGetailsComponent/GoodsBottomTab";

export default {
  name: "goodsGetails",
  components: {
    GoodsBottomTab,
    knowledgeServiceNav,
    PracticalInfo,
    // Recommended,
  },
  data() {
    return {
      // 商品详情
      goodsData: false,
    };
  },

  methods: {
    // 根据ID查询商品
    async goodsdata(id) {
      const params = {
        productId: id,
      };
      // form.append("");
      let res = await searchID(params);
      // console.log(res);
      if (res.code === 200) {
        this.goodsData = res.data;
      } else {
        this.$message({
          type: "error",
          message: "获取商品信息失败",
        });
      }
    },
  },

  watch: {
    $route(to, from) {
      this.goodsdata(this.$route.params.id);
    },
  },
  created() {
    this.goodsdata(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
.goodsGetails {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}
</style>
