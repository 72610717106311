<template>
  <div v-if="productType == 4">
    <div class="num">
      {{ quantity }}
    </div>
  </div>
  <div class="copyright-counter" v-else>
    <div class="number">{{ quantity }}</div>
    <div class="btn">
      <span class="add" @click="add">+</span>
      <span class="sub" @click="sub">-</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyrightCounter",
  props: {
    number: {
      type: Number,
      default: 1,
    },
    productType: {},
  },
  data() {
    return {
      quantity: this.number,
    };
  },
  methods: {
    add() {
      this.quantity++;
      this.$emit("childByValue", this.quantity);
    },
    sub() {
      this.quantity > 1 && this.quantity--;
      this.$emit("childByValue", this.quantity);
    },
  },
};
</script>

<style lang="scss" scoped>
.num {
  width: 44px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  border: 1px solid #dadada;
}
.copyright-counter {
  @include noselect;
  @include flex-start;
  width: 68px;
  border: 1px solid #dadada;
  color: #5e5e5e;
  .number {
    width: 44px;
    height: 46px;
    text-align: center;
    line-height: 46px;
    border-right: 1px solid #dadada;
  }
  .btn {
    @include flex-center(column);
    .add,
    .sub {
      width: 24px;
      height: 22.5px;
      text-align: center;
      line-height: 23.5px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      cursor: pointer;
    }
    .add {
      border-bottom: 1px solid #dadada;
    }
  }
}
</style>
