<template>
  <div class="PracticalInfo">
    <div class="copyright-practice-detail-content">
      <div class="copyright-practice-detail-content-main">
        <div class="copyright-practice-detail-content-main-picgroup">
          <PictureShowcase :imgList="imgList" v-if="imgList" height="380px" />
        </div>
        <div class="copyright-practice-detail-content-main-desc">
          <div class="title">{{ goodsData.productName }}</div>
          <div class="product-info">
            商品编码&nbsp;|&nbsp;{{ goodsData.productCode }}&nbsp;
          </div>
          <div class="block">
            <div class="leftTemp">
              <div class="specification_box">
                <div class="specification_item">
                  <span class="space"
                    >选择流程 <span class="ccc">(可多选)</span></span
                  >
                  <div>
                    <Process
                      :text="'Process'"
                      :processList="processList"
                      @handleProcessClick="handleProcessClick"
                    />
                  </div>
                </div>
                <div
                  class="specification_item"
                  v-if="goodsData.productName != '版权诉讼指引'"
                >
                  <span class="space">选择地域</span>
                  <div>
                    <Process
                      :text="'Area'"
                      :processList="areaUserVos"
                      @handleAreaClick="handleAreaClick"
                    />
                  </div>
                </div>
                <div
                  class="specification_item"
                  v-if="goodsData.productName != '版权诉讼指引'"
                >
                  <span class="space">选择执业律师</span>
                  <div>
                    <Process
                      @handleLawyerClick="handleLawyerClick"
                      :text="'Lawyer'"
                      :processList="LawyerList"
                      :LawyerIndex="LawyerIndex"
                    />
                  </div>
                </div>
              </div>

              <div class="price">
                <span class="space">价格</span>
                <span class="txt"
                  ><span class="small">￥</span>{{ price.toFixed(2) }}
                </span>
              </div>
              <div class="last">
                <span class="text">应用</span>
                <span class="busin"> 反抄袭各版本、成语各版本</span>
              </div>
            </div>
            <!-- <div class="right">
              <span class="evaltxt">累计评价</span>
              <span class="evalnum">50万+</span>
            </div> -->
          </div>

          <div class="last">
            <div class="busin" v-if="goodsData.productName != '版权诉讼指引'">
              服务提供商:
              <div class="busin_box">
                <span class="Tips1">{{
                  LawyerName ? LawyerName : "请选择执行律师"
                }}</span>
              </div>
            </div>
            <div class="busin" v-else>
              服务提供商:
              <div class="busin_box">
                <span class="Tips1">叶秀旻律师团队</span>
              </div>
            </div>
          </div>

          <div class="addshop">
            <CopyrightCounter :productType="4" @childByValue="childByValue" />
            <div class="shopcarBtn" @click="addToShopcar(goodsData)">
              立即购买
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="copyright-practice-detail-content-sets">
        <div @click="lick" class="collect">
          <img
            v-if="!like"
            :src="require('assets/images/goodsGetails/like.png')"
            alt=""
          />
          <img
            v-if="like"
            :src="require('assets/images/goodsGetails/like-red.png')"
            alt=""
          />
          收藏
        </div>
      </div> -->
    </div>

    <!-- 立即购买弹窗 -->
    <el-dialog
      width="500px"
      :close-on-click-modal="false"
      title="确认订单"
      :visible.sync="addShopDialog"
      class="addShopDialog"
    >
      <div class="shop_box">
        <div class="shop_left">
          <img
            class="img"
            :src="
              imgList
                ? $store.state.onlineBasePath + imgList[0].fileUrl
                : require('assets/images/no-shop-img100.png')
            "
            alt=""
          />
        </div>
        <div class="shop_right">
          <div class="txet_top">购买{{ goodsData.productName }}</div>
          <div class="text_bottom">
            <div class="text_left">单价: ¥{{ price }}</div>
            <div class="text_center">数量: {{ sum }}</div>
            <div class="text_right">
              ¥
              {{ price.toFixed(2) }}
            </div>
          </div>
        </div>
      </div>

      <div class="coupons_box">
        <div class="coupons_top">
          <i class="icon"></i>
          优惠码

          <el-input
            maxlength="6"
            @input="codeHandelInput(codeInput)"
            v-model="codeInput"
            placeholder="选填"
          ></el-input>
        </div>

        <div class="coupons_bottom" v-if="discountObj == null">
          <div class="text_left">无优惠</div>
          <div class="text_right">- ¥ 0.00</div>
        </div>

        <div class="coupons_bottom" v-else>
          <div class="text_left">{{ discountObj.codeName }}</div>
          <div class="text_right">
            - ¥
            {{ (price * (1 - discountObj.discountValue)).toFixed(2) }}
          </div>
        </div>
      </div>
      <div class="pay_box">
        <div
          class="pay_item"
          :class="payId == item.id ? 'bk' : ''"
          v-for="item in payList"
          @click="payId = item.id"
          :key="item.id"
        >
          <img :src="item.img" alt="" />
          <div class="name">{{ item.name }}</div>
        </div>
      </div>

      <div class="payment_text" v-if="discountObj == null">
        应付款:
        <span class="text">¥ {{ price.toFixed(2) }}</span>
      </div>

      <div class="payment_text" v-else>
        应付款:
        <span class="text"
          >¥ {{ (price * discountObj.discountValue).toFixed(2) }}</span
        >
      </div>

      <div slot="footer" class="dialog-footer" v-if="discountObj == null">
        <div class="payment_text">
          应付款:
          <span class="text"
            >¥
            {{ price.toFixed(2) }}
          </span>
        </div>
        <el-button type="primary" @click="submitOrder">提交订单</el-button>
      </div>

      <div slot="footer" class="dialog-footer" v-else>
        <div class="payment_text">
          应付款:
          <span class="text"
            >¥
            {{ (price * discountObj.discountValue).toFixed(2) }}
          </span>
        </div>
        <el-button type="primary" @click="submitOrder">提交订单</el-button>
      </div>
    </el-dialog>

    <!-- 扫码支付 -->
    <el-dialog
      title="扫码支付"
      :close-on-click-modal="false"
      :visible.sync="paymentPopup"
      @close="handleClosePaymentPopup"
      class="payment"
    >
      <div class="content">
        <div class="notes">请使用微信扫码下方二维码完成支付</div>
        <div class="imgBox">
          <img :src="qrCodeurl.codeImg" alt="" />
        </div>
        <!-- <el-button type="primary" @click="submitOrder">提交订单</el-button> -->
        <div class="price" v-if="discountObj == null">
          应付款：<span
            >￥<span>{{ price.toFixed(2) }}</span></span
          >
        </div>
        <div class="price" v-else>
          应付款：<span
            >￥<span>{{
              (price * discountObj.discountValue).toFixed(2)
            }}</span></span
          >
        </div>
      </div>
    </el-dialog>

    <!-- 支付成功 -->
    <el-dialog
      title="提示"
      :close-on-click-modal="false"
      :visible.sync="paymentSuccessPopup"
      class="paymentSuccess"
    >
      <div class="content">支付成功,是否前去订单中心</div>
      <div class="footer">
        <div
          class="footer-btn"
          @click="
            paymentSuccessPopup = false;
            paymentPopup = false;
            addshopDialog = false;
            $router.push({
              name: 'OrderCenter',
            });
          "
        >
          确定
        </div>
        <div
          class="footer-btn"
          @click="
            paymentSuccessPopup = false;
            paymentPopup = false;
            addshopDialog = false;
          "
        >
          取消
        </div>
      </div>
    </el-dialog>

    <!-- 下载版权诉讼弹窗 -->
    <el-dialog
      title="提示"
      :close-on-click-modal="false"
      :visible.sync="downloadLawsuitDialog"
      class="paymentSuccess"
    >
      <div class="content">
        购买成功,已经自动存入“智能网盘/系统文件/专业服务”文件夹中,是否立即下载
      </div>
      <div class="footer">
        <div class="footer-btn" @click="downloadLawsuit(oid)">确定</div>
        <div
          class="footer-btn"
          @click="
            downloadLawsuitDialog = false;
            paymentPopup = false;
            addshopDialog = false;
          "
        >
          取消
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pcOrderPay } from "api/common";
import qs from "qs";
import {
  payOtherOrderPay,
  PaywechatOrderQueryQrcode,
  AddCopyrightPractice,
  searchFile,
  sideIsbuy,
  findDiscount,
  bind,
  findDiscountInfoByCode,
  FindProcess,
  exportProjectFile,
  addOrDel,
  findDiscountByUser,
} from "api/goodsGetails";
import PictureShowcase from "components/GoodsGetailsComponent/PictureShowcase";
import Process from "components/copyrightDetails/processSpecifications";

import CopyrightCounter from "components/GoodsGetailsComponent/CopyrightCounter";
export default {
  name: "GoodsGetailsInfo",
  components: {
    PictureShowcase,
    CopyrightCounter,
    Process,
  },
  props: {
    goodsData: {},
  },
  data() {
    return {
      payId: 1,
      payList: [
        {
          name: "微信",
          id: 1,
          img: require("assets/images/weixin.png"),
        },
        {
          name: "支付宝",
          id: 2,
          img: require("assets/images/zhifubao.png"),
        },
      ],
      // 下载版权诉讼弹窗
      downloadLawsuitDialog: false,
      //选中的律师名字
      LawyerName: "",
      LawyerIndex: -2,
      // 律师id
      LawyerId: "",
      // 地区id
      AreaId: "",
      // 律师数组
      LawyerList: [],
      // 选择阶段流程数组
      processList: [],
      // 选中流程id数组
      projectdetailIds: [],
      // 地区数组
      areaUserVos: [],
      // 优惠码输入框
      codeInput: "",
      // 选中的规格
      specificationitem: {},
      // 选择流程弹窗
      showProcess: false,
      // 选择承办人弹窗
      showUndertaker: false,
      // 是否喜欢
      like: false,
      // 支付成功弹窗
      paymentSuccessPopup: false,
      // 扫码弹窗
      paymentPopup: false,
      // 确认订单弹窗
      addShopDialog: false,
      // 商品数量
      sum: 1,
      // 价格
      price: 0,
      // 商品id
      id: "",
      // 订单 oid
      oid: "",
      // 商品图片
      imgList: false,
      // 二维码URL
      qrCodeurl: {},
      //定时器
      timer: null,
      // 类型
      type: null,
      discountObj: null,
    };
  },
  methods: {
    //收藏商品
    async lick() {
      let data = {
        productId: this.id,
      };
      let res = await addOrDel(data);
      if (res.code == 200) {
        this.like = !this.like;
        if (this.like) {
          this.$message({
            type: "success",
            message: "收藏成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "取消收藏",
          });
        }
      }
    },
    // 下载版权诉讼指引
    async downloadLawsuit(orderId) {
      const data = {
        orderId,
      };
      this.downloadLawsuitDialog = false;
      this.paymentPopup = false;
      this.addshopDialog = false;

      const res = await exportProjectFile(data);
      const blob = new Blob([res], {
        type: "application/octet-stream",
      });
      const fileName = "版权诉讼指引.docx";
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    // 流程数组
    handleProcessClick(val) {
      let arr = [];
      let sum = 0;
      val.forEach((item) => {
        if (item.check) {
          sum += item.price;
          arr.push(item.projectdetailId);
        }
      });

      this.price = sum;
      this.projectdetailIds = arr;
    },
    // 地区
    handleAreaClick(val) {
      this.AreaId = val.id;
      this.LawyerList = val.lawyerUserList;
      if (this.LawyerIndex == -1) {
        this.LawyerIndex = -2;
      } else {
        this.LawyerIndex = -1;
      }
      this.LawyerName = "";
      this.LawyerId = "";
    },
    // 律师
    handleLawyerClick(val) {
      this.LawyerName = val.name;
      this.LawyerId = val.userId;
    },
    // 搜索查询商品图片
    async goodsfils(id) {
      const data = {
        productId: id,
      };
      // JSON.stringify(data)
      const res = await searchFile(data);
      if (res.code === 200) {
        this.imgList = res.data;
      } else if (res.code === 2001) {
        this.imgList = res.data;
      } else {
        this.$message({
          type: "error",
          message: "获取商品图片失败",
        });
      }
    },

    // 监听优惠码输入框
    codeHandelInput(val) {
      if (val.length == 6) {
        this.FindDiscountInfoByCode(val);
      }
    },

    async FindDiscountInfoByCode(code) {
      const data = {
        productId: this.id,
        specificationId: this.specificationitem.id,
        code,
      };

      const res = await findDiscountInfoByCode(data);
      if (res.code === 200) {
        this.addShopDialog = true;
        this.discountObj = res.data;
        this.codeInput = res.data.code;
      } else if (res.code === 2023) {
        this.addShopDialog = true;
        this.discountObj = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    //  绑定推荐码
    async bindingCode(val) {
      const data = {
        code: val,
      };
      const res = await bind(data);
      if (res.code == 200) {
        this.getDiscount(val);
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // vip/svip优惠
    async vipDiscounts() {
      const data = {
        productId: this.id,
        specificationId: this.specificationitem.id,
      };
      const res = await findDiscountByUser(data);
      if (res.code === 200) {
        this.addShopDialog = true;
        this.discountObj = res.data;
        this.codeInput = res.data.code;
      } else if (res.code === 2023) {
        this.addShopDialog = true;
        this.discountObj = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 获取优惠
    async getDiscount(code) {
      const data = {
        productId: this.id,
        specificationId: this.specificationitem.id,
        code,
      };
      const res = await findDiscount(data);
      if (res.code === 200) {
        this.addShopDialog = true;
        this.discountObj = res.data;
        this.codeInput = res.data.code;
      } else if (res.code === 2023) {
        this.addShopDialog = true;
        this.discountObj = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 商品数量
    childByValue(sum) {
      this.sum = sum;
    },

    // 立即购买
    addToShopcar() {
      this.$store.commit("CHECK_USER_OPTIONS", {
        afterCheck: async () => {
          if (this.goodsData.productName == "版权诉讼指引") {
            if (this.projectdetailIds.length == 0) {
              this.$message("请选择流程");
              return;
            }
          } else {
            if (this.projectdetailIds.length == 0) {
              this.$message("请选择流程");
              return;
            }
            if (this.AreaId == "") {
              this.$message("请选择地区");
              return;
            }
            if (this.LawyerId == "") {
              this.$message("请选择律师");
              return;
            }
          }

          let data = {
            productCode: this.goodsData.productCode,
          };

          const res = await sideIsbuy(data);
          if (res.code == 200) {
            this.addShopDialog = true;
            // this.getDiscount();
            this.vipDiscounts();
          } else {
            this.$message({
              type: "success",
              message: res.message,
            });
          }
        },
      });
    },

    //授权生成支付二维码
    async QrCode() {
      let that = this;
      const data = {
        oid: this.oid,
      };

      const res = await payOtherOrderPay(data);

      if (res.code === 200) {
        this.paymentPopup = true;
        this.qrCodeurl = res.data;
        this.timer = window.setInterval(function () {
          that.wechatOrderQueryQrcode();
        }, 1500);
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 调用微信支付的查询订单接口
    async wechatOrderQueryQrcode() {
      const data = {
        oid: this.oid,
      };

      const res = await PaywechatOrderQueryQrcode(data);
      if (res.code == 200) {
        if (this.goodsData.productCode == "P202203022048470287") {
          this.downloadLawsuitDialog = true;
        } else {
          this.$router.push({
            name: "AgencyEntrustment",
          });
        }
      }
    },

    // 新增订单
    async submitOrder() {
      return this.addCopyrightPractice();
    },

    // 新增版权实务订单
    async addCopyrightPractice() {
      const data = {
        productId: this.id,
        projectdetailIds: this.projectdetailIds,
        handleId: this.LawyerId,
        payType: this.payId == 1 ? 1 : 2,
      };
      const res = await AddCopyrightPractice(data);
      if (res.code === 200) {
        this.oid = res.data;

        if (this.payId == 1) {
          this.QrCode();
        } else {
          this.AlipaypcOrderPay(this.oid);
        }
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 支付宝支付
    async AlipaypcOrderPay(oid) {
      const data = {
        oid,
      };

      const res = await pcOrderPay(qs.stringify(data));
      const divForm = document.getElementById("divForm");
      if (divForm) {
        document.body.removeChild(divForm);
      }
      const div = document.createElement("div");
      div.id = "divForm"; // 设置id
      div.innerHTML = res.data;
      document.body.appendChild(div);
      // 利用id去获取表单
      document
        .getElementById("divForm")
        .children[0].setAttribute("target", "_blank");
      document.getElementById("divForm").children[0].submit();
    },

    // 获取版权实务规格
    async findProcess(id) {
      let form = new FormData();
      form.append("productId", id);

      const res = await FindProcess(form);
      if (res.code !== 200) {
        return this.$message({
          type: "error",
          message: res.message,
        });
      }

      // this.manHour = res.data.manHour;
      this.processList = res.data.projectdetailList.map((item) => {
        // console.log(item);
        item.check = false;
        return item;
      });
      this.areaUserVos = res.data.areaUserVos;
      // this.undertakerList = res.data.teamUserList;
    },

    // 关闭支付弹窗停止定时器
    handleClosePaymentPopup() {
      window.clearInterval(this.timer);
    },
  },
  watch: {
    goodsData: {
      handler(newVal, oldVal) {
        // newVal是获取的是新的值
        this.goodsData = newVal;
      },
      deep: true,
    },
    $route(to, from) {
      this.price = 0;

      this.LawyerId = "";
      this.projectdetailIds = [];
      this.LawyerName = "";
      this.goodsfils(this.$route.params.id);
      this.findProcess(this.$route.params.id);
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.type = this.$route.params.type;
    this.goodsfils(this.$route.params.id);
    this.findProcess(this.$route.params.id);
  },
  beforeDestroy() {
    //页面销毁前，清除定时器
    window.clearInterval(this.timer); //清除timer定时器
  },
};
</script>

<style lang="scss" scoped>
.PracticalInfo {
  .copyright-practice-detail-content {
    margin-top: 20px;

    .copyright-practice-detail-content-main {
      display: flex;

      .copyright-practice-detail-content-main-desc {
        flex: 1;
        margin-left: 40px;

        .title {
          margin: 15px 0;
          font-size: 18px;
          font-weight: bold;
        }

        .product-info {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }

        .block {
          @include flex-start;
          margin-top: 20px;
          padding: 15px 0 0 0;

          position: relative;

          .leftTemp {
            flex: 1;
            .specification .space,
            .specification .specification_text,
            .price .space {
              display: inline-block;
              width: 60px;
              text-align-last: justify;
              text-align: justify;
              margin-top: 10px;
              margin-right: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
            }
            .specification_box {
              font-size: 14px;
              font-weight: 400;
              color: #666666;

              .specification_item {
                display: flex;
                align-items: center;
                margin: 0 0 10px 0;
                .space {
                  display: inline-block;
                  width: 120px;

                  .ccc {
                    color: #ccc;
                  }
                }
              }
            }
            .price {
              .txt {
                .small {
                  font-size: 12px;
                }
                font-size: 15px;
                font-weight: 500;
                color: #eb441e;
              }
            }
            .margin {
              margin-top: 10px;
            }
          }

          .right {
            @include flex-center(column);
            margin-left: 20px;
            font-size: 14px;
            font-weight: 500;
            .evaltxt {
              white-space: nowrap;
              color: #666666;
            }
            .evalnum {
              margin-top: 10px;
              color: $color-text-active;
            }
          }
        }

        .last {
          display: flex;
          margin: 25px 0 0 0;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          .text {
            width: 70px;
            text-align-last: justify;
            text-align: justify;
            margin-right: 20px;
          }

          .busin {
            .busin_box {
              padding: 5px 15px;
              display: inline-block;

              background-size: 100% 100%;
              border-radius: 15px;
              img {
                margin-right: 5px;
              }
            }
          }
          .follow {
            cursor: pointer;
          }
          .follow {
            user-select: none;
            span {
              margin-left: 5px;
            }
            .followed-txt {
              color: #666666;
            }
            .txt {
              color: $color-text-active;
            }
          }
        }

        .addshop {
          @include flex-start;
          margin-top: 68px;
          .shopcarBtn {
            width: 160px;
            height: 48px;
            margin-left: 10px;
            background: #eb441e;
            border-radius: 2px;
            text-align: center;
            line-height: 48px;
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }

    .copyright-practice-detail-content-sets {
      @include noselect;
      @include flex-start;
      margin-top: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      cursor: pointer;
      .collect {
        margin-right: 20px;
      }
      .collect,
      .share {
        @include flex-start;
        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }
  }
}

// 立即购买弹窗
.addShopDialog {
  ::v-deep .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
  }
  .shop_box {
    padding: 0 0 20px 0;
    border-bottom: 1px solid #cccc;
    display: flex;
    // justify-content: center;
    align-items: center;
    .shop_left {
      margin: 0 10px;
      .img {
        width: 60px;
        height: 60px;
      }
    }

    .shop_right {
      display: flex;
      flex: 1;
      flex-direction: column;
      .txet_top {
        color: #000;
        font-size: 16px;
        margin: 0 0 15px 0;
      }

      .text_bottom {
        display: flex;
        justify-content: space-between;
        padding: 0 20px 0 0;
        font-size: 14px;
        .text_left {
          color: #999999;
        }

        .text_center {
          color: #999999;
        }

        .text_right {
          color: #eb441e;
        }
      }
    }
  }
  .coupons_box {
    padding: 20px 0;
    border-bottom: 1px solid #cccc;
    .coupons_top {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 16px;
      .icon {
        width: 14px;
        height: 10px;
        @include backgroundGroup("@~assets/images/coupons.png");
        margin: 0 10px 0 5px;
      }

      .el-input {
        width: 150px;
        height: 30px;
        margin-left: 20px;
        ::v-deep .el-input__inner {
          height: 30px;
        }
      }
    }

    .coupons_bottom {
      margin-top: 15px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      padding: 0 20px 0 30px;
      .text_left {
        color: #999999;
      }

      .text_right {
        color: #eb441e;
      }
    }
  }

  .pay_box {
    margin-top: 20px;
    display: flex;
    .pay_item {
      cursor: pointer;
      box-sizing: border-box;
      padding: 20px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 40px;
      }
    }

    .bk {
      border: 1px solid red;
    }
  }

  .payment_text {
    padding: 20px 0 70px 0;
    width: 100%;
    text-align: right;
    font-size: 14px;
    color: #000;
    .text {
      color: #eb441e;
      margin-right: 15px;
    }
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
    .payment_text {
      padding: 0;
      font-weight: 800;
      font-size: 16px;
    }
  }
}

// 支付弹窗
.payment {
  ::v-deep .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    width: 500px;
    height: 390px;
    background: #ffffff;
    border: 0px solid #818790;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      height: 42px;
      background: #f4f6f7;
      padding: 0;
      line-height: 42px;
      .el-dialog__title {
        margin-left: 15px;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: #393939;
      }
      .el-dialog__headerbtn {
        top: $font-size-small;
        right: $font-size-small;
        font-size: $font-size-large;
      }
    }
    .el-dialog__body {
      padding: 65px 20px;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .notes {
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
        }
        .imgBox {
          width: 110px;
          height: 110px;
          margin-top: 30px;
          img {
            width: 110px;
            height: 110px;
          }
        }
        .price {
          margin-top: 30px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
          span {
            color: #eb441e;
            span {
              font-size: $font-size-large;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

// 支付成功弹窗
.paymentSuccess {
  ::v-deep .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    width: 340px;
    min-height: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      padding: 12px 12px 0px;
      .el-dialog__title {
        font-size: $font-size-small;
        font-family: PingFang SC;
        font-weight: 400;
        color: $color-text-gray;
      }
      .el-dialog__headerbtn {
        top: 12px;
        right: 12px;
      }
    }
    .el-dialog__body {
      padding: 15px 39px;
      .content {
        margin: 40px 0;
        text-align: center;
        font-size: $font-size-medium-x;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-black;
        line-height: 26px;
        span {
          color: $color-bg-heightlight;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        .footer-btn {
          font-size: 18px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-bg-heightlight;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
